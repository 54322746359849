import { last, sum } from '@fxts/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
export interface DataType {
    amount: string;
    rate: string;
    term: string;
}
interface qsObject {
    rate: number;
    term: number;
    amount: number;
    resultInterest: number;
    resultAmount: number;
}

export interface useFormType {
    rate: number;
    priorRate: number;
    amount: number;
    term: number;

    totalRate: number;
    totalAmount: number;
    resultInterest: number;
    resultAmount: number;
}

export const Main = () => {
    return <></>;
};
